<template>
  <div>Template Custom Range Date Picker</div>
</template>
<script>
import { mapGetters } from 'vuex';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import duration from 'dayjs/plugin/duration';
import gMixin from '@/utils/mixins';
import imageUrlMixin from '@/utils/imageUrlMixin';

require('dayjs/locale/he');
require('dayjs/locale/ru');
require('dayjs/locale/ar');

dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.extend(duration);

export default {
  name: 'TemplateCustomDatePickerRange',
  props: {
    availableDates: {
      type: Array,
      required: true,
    },
    calendarShow: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'tabs',
    },
    destinationText: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [gMixin, imageUrlMixin],
  data() {
    return {
      // selectionDate1: null,
      // selectionDate2: null,
      weekDays: [],
      startMonthYear: dayjs().format('YYYY-MM'),
      today: dayjs().startOf('day'),
      show: false,
      showCalendar: false,
      mode: 'startSelect',
      endDatesInfo: [],
      endUnableDatesInfo: [],
      containerWidth: 0,
      query: this.$route.query,
      // scrollPosition: 0,
      // clientHeight: 0,
      // scrollHeight: 0,
      // availHeight: window.screen.availHeight,
    };
  },
  computed: {
    ...mapGetters({
      lang: 'GET_LANGUAGE',
      device: 'GET_DEVICE',
      holidayList: 'GET_HOLIDAY_DATA',
      isLoading: 'GET_DATE_LOADING_STATE',
      isFcAgentMarketerMode: 'GET_FC_AGENT_MARKETER_MODE',
    }),
    // holidayList() {
    //   return this.holiday.holidays;
    // },
    thisMonth() {
      return dayjs().month();
    },
    thisYear() {
      return dayjs().year();
    },
    ableLastMonthCount() {
      const { availableDates } = this;
      if (!availableDates || (availableDates && availableDates.length === 0)) return 2;

      const firstAbleDay = availableDates.sort((a, b) => new Date(b.date) - new Date(a.date))[availableDates.length - 1].date;
      const lastAbleDay = availableDates.sort((a, b) => new Date(a.date) - new Date(b.date))[availableDates.length - 1].date;
      const lastMonthFirstDay = dayjs(lastAbleDay).set('date', 28);
      const nowMonthFirstDay = dayjs(firstAbleDay).set('date', 1);
      return dayjs(lastMonthFirstDay).diff(dayjs(nowMonthFirstDay), 'month') + 2;
    },
    monthYears() {
      const monthYears = [];
      const count = this.isDesktop ? 2 : this.ableLastMonthCount;
      for (let i = 0; i < count; i += 1) {
        const monthYear = dayjs(`${this.startMonthYear}-01`).add(i, 'months');
        monthYears.push(monthYear);
      }
      return monthYears;
    },
    datesInfo() {
      const dates = {};
      this.setStartDate();
      this.availableDates.forEach((rec) => {
        if (dates[rec.date] && rec.dur[0] !== 0 && rec.av !== 0) {
          dates[rec.date].push(rec);
        } else if (rec.dur[0] !== 0 && rec.av !== 0) dates[rec.date] = [rec];
      });
      return dates;
    },
    unavDatesInfo() {
      const dates = {};
      this.setStartDate();
      this.availableDates.forEach((rec) => {
        if (dates[rec.date] && rec.dur[0] !== 0 && rec.av === 0) {
          dates[rec.date].push(rec);
        } else if (rec.dur[0] !== 0 && rec.av === 0) dates[rec.date] = [rec];
      });
      return dates;
    },
    inputText() {
      let text = '';
      if (this.selectionDate1) {
        text += `${this.selectionDate1.format('DD/MM/YY')} - `;
      }
      if (this.selectionDate2) {
        text += this.selectionDate2.format('DD/MM/YY');
      }
      return text;
    },
    noOfMonthsInRow() {
      let slots = Math.floor(this.containerWidth / 240);
      if (slots > 2) {
        slots = 2;
      }
      return slots;
    },
    selectionDate1: {
      get() {
        return this.$store.getters.GET_SEARCH_CONTENT.from ? this.$store.getters.GET_SEARCH_CONTENT.from : '';
      },
      set(value) {
        this.$store.dispatch('SET_SEARCH_ITEM', ['from', value]);
      },
    },
    selectionDate2: {
      get() {
        return this.$store.getters.GET_SEARCH_CONTENT.to ? this.$store.getters.GET_SEARCH_CONTENT.to : '';
      },
      set(value) {
        this.$store.dispatch('SET_SEARCH_ITEM', ['to', value]);
      },
    },
    isDesktop() {
      return this.device === 'desktop';
    },
    isDomainVercel() {
      return window.location.host.includes('vercel') || window.location.host.includes('localhost');
    },
  },
  watch: {
    selectionDate1() {
      this.emitDates();
    },
    selectionDate2() {
      this.emitDates();
    },
    lang() {
      dayjs.locale(this.lang);
      this.getWeekName();
      this.nextMonth();
      this.previousMonth();
    },
    calendarShow() {
      this.clearDatesSelection();
      if (this.isDesktop) {
        this.show = this.calendarShow;
      } else {
        this.showCalendar = this.calendarShow;
      }
    },
    showCalendar() {
      if (this.showCalendar) {
        this.clearDatesSelection();
      }
      // this.addRemoveScrollBehavior();
    },
    $route: {
      immediate: true,
      handler() {
        if (this.isFcAgentMarketerMode) return;
        setTimeout(() => {
          this.selectionDate1 = (this.$route.query.fromDate) ? dayjs(this.$route.query.fromDate) : null;
          this.selectionDate2 = (this.$route.query.toDate) ? dayjs(this.$route.query.toDate) : null;
        }, 500);
      },
      deep: true,
    },
    show() {
      if (this.type === 'side' && this.show) {
        this.clearDatesSelection();
      }
    },
  },
  created() {
    dayjs.locale(this.lang);
    this.getWeekName();

    window.addEventListener('resize', this.refreshContainerSize);
  },
  mounted() {
    this.refreshContainerSize();
  },
  destroyed() {
    window.removeEventListener('resize', this.refreshContainerSize);
  },
  methods: {
    setStartDate() {
      // const firstDate = this.availableDates.filter((item) => item.dur[0] !== 0);
      // const minDate = Math.min.apply(
      //   Math,
      //   this.availableDates.filter((item) => item.dur[0] !== 0).map((item) => item.date)
      // );
      const minDate = this.availableDates.filter((item) => item.dur[0] !== 0).map((item) => item.date)
        .sort()[0];
      this.startMonthYear = minDate ? dayjs(minDate).format('YYYY-MM') : dayjs().format('YYYY-MM');
    },
    getWeekName() {
      this.weekDays = [
        this.$t('weekShortName.sun'),
        this.$t('weekShortName.mon'),
        this.$t('weekShortName.tue'),
        this.$t('weekShortName.wed'),
        this.$t('weekShortName.thu'),
        this.$t('weekShortName.fri'),
        this.$t('weekShortName.sat'),
      ];
    },
    emitDates() {
      if (this.selectionDate1 && this.isDesktop) this.startMonthYear = dayjs(this.selectionDate1).format('YYYY-MM');
      this.$emit('dates', {
        from: this.selectionDate1 ? this.selectionDate1.format('YYYY-MM-DD') : '',
        to: this.selectionDate2 ? this.selectionDate2.format('YYYY-MM-DD') : '',
      });
    },
    weekWiseDays(monthYear) {
      const days = [];
      let day = monthYear.clone();
      while (day.format('YYYY-MM') === monthYear.format('YYYY-MM')) {
        days.push(day);
        day = day.add(1, 'days');
      }

      const weekWiseDays = [];
      let i = 0;
      days.forEach((dday) => {
        if (!weekWiseDays[i]) {
          weekWiseDays[i] = [];
        }
        const position = dday.day();
        if (position != null) {
          weekWiseDays[i][position] = dday;
          if (position === this.weekDays.length - 1) {
            i += 1;
          }
        }
      });
      return weekWiseDays;
    },
    displayMonthYear(monthYear) {
      return `${monthYear.format('MMMM')} ${monthYear.format('YYYY')}`;
    },
    toggleCalendar() {
      if (this.isDesktop) {
        this.show = !this.show;
        if (this.show) {
          this.mode = 'startSelect';
        }
        this.refreshContainerSize();
      } else {
        this.showCalendar = !this.showCalendar;
      }
    },
    nextMonth() {
      this.startMonthYear = dayjs(`${this.startMonthYear}-01`).add(1, 'months').format('YYYY-MM');
    },
    previousMonth() {
      this.startMonthYear = dayjs(`${this.startMonthYear}-01`).subtract(1, 'months').format('YYYY-MM');
    },
    isAvailable(day) {
      if (!day) {
        return false;
      }
      if (!day.isSameOrAfter(this.today)) {
        return false;
      }
      if (this.mode === 'startSelect') {
        return !!this.datesInfo[day.format('YYYY-MM-DD')];
      } else if (this.mode === 'endSelect') {
        return !!this.endDatesInfo[day.format('YYYY-MM-DD')];
      }
      return false;
    },
    isUnavailable(day) {
      if (!day) {
        return false;
      }
      if (!day.isSameOrAfter(this.today)) {
        return false;
      }
      if (this.mode === 'startSelect') {
        return !!this.unavDatesInfo[day.format('YYYY-MM-DD')];
      } else if (this.mode === 'endSelect') {
        return !!this.endUnableDatesInfo[day.format('YYYY-MM-DD')];
      }
      return false;
    },
    dayPrice(day) {
      let dayString = '';
      if (this.mode === 'startSelect') {
        dayString = day.format('YYYY-MM-DD');
        if (this.datesInfo[dayString]) {
          const symbol = this.datesInfo[dayString][0].cc;
          return `${this.getPriceWithSymbol(symbol, this.getMinPrice(this.datesInfo, day))}`;
        } else if (this.unavDatesInfo[dayString]) {
          const symbol = this.unavDatesInfo[dayString][0].cc;
          return `${this.getPriceWithSymbol(symbol, this.getMinPrice(this.unavDatesInfo, day))}`;
        } else {
          return '';
        }
      } else if (this.mode === 'endSelect') {
        dayString = day.format('YYYY-MM-DD');
        if (this.endDatesInfo[dayString]) {
          const symbol = this.endDatesInfo[dayString].cc;
          return `${this.getPriceWithSymbol(symbol, this.endDatesInfo[dayString].price)}`;
        } else if (this.endUnableDatesInfo[dayString]) {
          const symbol = this.endUnableDatesInfo[dayString].cc;
          return `${this.getPriceWithSymbol(symbol, this.endUnableDatesInfo[dayString].price)}`;
        } else {
          return '';
        }
      }
      return '';
    },
    dayDuration(day) {
      let dayString = '';
      if (this.mode === 'endSelect') {
        dayString = day.format('YYYY-MM-DD');
        if (this.endDatesInfo[dayString]) {
          return this.endDatesInfo[dayString].dur;
        } else if (this.endUnableDatesInfo[dayString]) {
          return this.endUnableDatesInfo[dayString].dur;
        } else {
          return '';
        }
      }
      return '';
    },
    selectDate(day) {
      if (this.mode === 'startSelect') {
        this.selectionDate1 = day;
        this.selectionDate2 = null;

        /**
         * Get available date when select first date.
         */
        this.endDatesInfo = [];
        const info = this.datesInfo[day.format('YYYY-MM-DD')];
        for (let i = 0; i < info.length; i += 1) {
          this.endDatesInfo[day.add(info[i].dur[0], 'days').format('YYYY-MM-DD')] = {
            price: info[i].durPrice,
            cc: info[i].cc,
            dur: info[i].dur[0],
          };
        }

        /**
         * Get unavailable date when select first date.
         */
        this.endUnableDatesInfo = [];
        const unableInfo = this.unavDatesInfo[day.format('YYYY-MM-DD')] || [];
        for (let i = 0; i < unableInfo.length; i += 1) {
          this.endUnableDatesInfo[day.add(unableInfo[i].dur[0], 'days').format('YYYY-MM-DD')] = {
            price: unableInfo[i].durPrice,
            cc: unableInfo[i].cc,
            dur: unableInfo[i].dur[0],
          };
        }

        this.mode = 'endSelect';
      } else {
        this.selectionDate2 = day;
        if (this.type === 'side') this.finalizeSelection();
      }
    },
    isStartDate(day) {
      if (!day) {
        return false;
      }
      if (
        this.selectionDate1
        && this.selectionDate1.format('YYYY-MM-DD') === day.format('YYYY-MM-DD')
      ) {
        return true;
      }
      return false;
    },
    isToday(day) {
      if (!day) {
        return false;
      }
      if (this.today.format('YYYY-MM-DD') === day.format('YYYY-MM-DD')) {
        return true;
      }
      return false;
    },
    clearDatesSelection() {
      this.selectionDate1 = null;
      this.selectionDate2 = null;
      this.mode = 'startSelect';
      this.$emit('finalDateSelect', false);
    },
    finalizeSelection() {
      if (this.isDesktop) {
        this.show = false;
      } else {
        this.showCalendar = false;
      }
      this.$emit('finalDateSelect', true);
    },
    isUnderSelection(day) {
      if (!day) {
        return false;
      }
      if (
        this.selectionDate1
        && this.selectionDate2
        && day.isSameOrAfter(this.selectionDate1)
        && day.isSameOrBefore(this.selectionDate2)
      ) {
        return true;
      }
      return false;
    },
    isHoliday(day) {
      if (!day) {
        return '';
      }
      const formattedDay = day.format('YYYY-MM-DD');
      return this.holidayList?.find((item) => item.active && item.date === formattedDay)?.hebrew;
    },
    refreshContainerSize() {
      const width = this.type !== 'side' ? 550 : 250;// this.$refs.inputControl?.clientWidth;
      this.containerWidth = width < 250 ? 250 : width;
    },
    getMinPrice(obj, day) {
      // eslint-disable-next-line prefer-spread
      return Math.min.apply(
        Math,
        obj[day.format('YYYY-MM-DD')].map((item) => item.price),
      );
    },
  },
};
</script>
